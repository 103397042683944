
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import { dispatchLogIn } from "@/store/main/actions";


@Component
export default class Login extends Vue {
  public email: string = "";
  public password: string = "";
  public appName = appName;
  public error: string = "";

  public get loginError() {
    return readLoginError(this.$store);
  }

  public mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email.valueOf().toString();
    }
  }

  public getReferralWithQueryParams() {
    if (typeof this.$route.query.redirect === "string") {
      const urlParams = new URLSearchParams();
      if (typeof this.$route.query.queryparams === "object") {
        for (const [k, v] of Object.entries(this.$route.query.queryparams)) {
          if (typeof v === "string") {
            urlParams.set(k, v);
          }
        }
        return this.$route.query.redirect + "?" + urlParams.toString();
      }
      return this.$route.query.redirect;
    }
    return "";
  }

  public toRegister() {
    if (typeof this.$route.query.redirect === "string") {
      const urlParams = new URLSearchParams();
      if (typeof this.$route.query.queryparams === "object") {
        for (const [k, v] of Object.entries(this.$route.query.queryparams)) {
          if (typeof v === "string") {
            urlParams.set(k, v);
          }
        }
        this.$router.push({
          path: `/register?redirect=${this.$route.query.redirect.toString() + "?" + urlParams.toString()}`,
        });

      } else {
        this.$router.push({
          path: `/register?redirect=${this.$route.query.redirect.toString()}`,
        });

      }
    } else {
      this.$router.push({
        path: `/register`,
      });
    }
  }

  public submit() {
    dispatchLogIn(this.$store, {
      username: this.email,
      password: this.password,
      to: this.getReferralWithQueryParams(),
    })
      .then((response) => {
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response !== undefined) {
          if (e.response.status === 422) {
            this.error = "Please provide a valid username and password";
          } else {
            this.error = e.response.data.detail;
          }
        } else {
          this.error = "Server is not responding";
        }
      });
  }
}

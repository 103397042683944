
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readToken, readUserProfile } from "@/store/main/getters";
import { dispatchLogIn } from "@/store/main/actions";

@Component
export default class PickWorkspace extends Vue {
  public email: string = "";
  public password: string = "";
  public appName = appName;
  public error: string = "";
  public loginChallenge: any = "";
  public loading: boolean = false;
  public alreadyConnectedIds: number[] = [];

  get token() {
    return readToken(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public mounted() {
    this.loginChallenge = this.$route.query.login_challenge;
    this.getAlreadyConnectedWorkspaces();
  }

  public async getAlreadyConnectedWorkspaces() {
    await api
      .getAlreadyConnectedWorkspaces(this.token, this.loginChallenge)
      .then((resp) => {
        this.alreadyConnectedIds = resp.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public async pickWorkspace(workspaceId, consent) {
    this.error = "";
    this.loading = true;
    await api
      .pickWorkspace(this.token, this.loginChallenge, workspaceId, consent)
      .then((resp) => {
        this.loading = false;
        window.location.replace(resp.data.redirect_url);
      })
      .catch((err) => {
        this.loading = false;
        this.error = err.response;
        console.log(err);
      });
  }
}


import { api } from "@/api";
import { appName } from "@/env";
import { readToken, readUserProfile } from "@/store/main/getters";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CreateWorkspaceToken extends Vue {
  public email: string = "";
  public password: string = "";
  public appName = appName;
  public error: string = "";
  public loading: boolean = false;
  public tmpBearer: string = "";
  public copyIcon: string = "content_copy";

  get token() {
    return readToken(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public mounted() {}

  public toggleIcon() {
    // Set icon to check
    this.copyIcon = "check";

    // Reset icon to copy after 1 second
    setTimeout(() => {
      this.copyIcon = "content_copy";
    }, 1000);
  }

  public copyText() {
    this.toggleIcon();
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.tmpBearer;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public async pickWorkspace(workspaceId, consent) {
    this.error = "";
    this.loading = true;
    await api
      .createClientAndToken(this.token, workspaceId)
      .then((resp) => {
        this.tmpBearer = resp.data;
      })
      .catch((err) => {
        this.loading = false;
        this.error = err.response;
        console.log(err);
      });
  }
}

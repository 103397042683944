
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readToken } from "@/store/main/getters";
import { dispatchLogIn } from "@/store/main/actions";

@Component
export default class Consent extends Vue {
  public email: string = "";
  public password: string = "";
  public appName = appName;
  public error: string = "";
  public consentChallenge: any = "";
  public loading: boolean = false;
  public clientName: string = "";
  public clientLogoName: string = "";

  get token() {
    return readToken(this.$store);
  }

  public mounted() {
    this.consentChallenge = this.$route.query.consent_challenge;
    this.getClient();
  }

  public async getClient() {
    await api
      .getClient(this.token, this.consentChallenge)
      .then((resp) => {
        this.clientName = resp.data.client_name;
        this.clientLogoName = resp.data.logo;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public async consent(accept) {
    this.loading = true;
    await api
      .consent(this.token, this.consentChallenge, accept)
      .then((resp) => {
        this.loading = false;
        window.location.replace(resp.data.redirect_url);
      })
      .catch((err) => {
        this.loading = false;
        this.error = err.response;
        console.log(err);
      });
  }
}

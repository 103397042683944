
import { api } from "@/api";
import Tos from "@/components/Tos.vue";
import { appName } from "@/env";
import { dispatchLogIn } from "@/store/main/actions";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    Tos,
  },
})
export default class Login extends Vue {
  public email: string = "";
  public password: string = "";
  public terms: boolean = false;
  public showTerms: boolean = false;
  public name: string = "";
  public result: any = null;
  public error: any = null;

  public appName = appName;

  public submit() {
    this.result = null;
    this.error = null;
    api
      .register(this.name, this.email, this.password)
      .then((r) => {
        this.result = r;
        this.login();
        // if (typeof this.$route.query.redirect === "string") {
        //   this.$router.push({
        //     path: `/login?redirect=${this.$route.query.redirect.toString()}`,
        //   });
        // } else {
        //   this.$router.push({
        //     path: `/login`,
        //   });
        // }
        // if (typeof this.$route.query.redirect === "string") {
        //   this.$router.push({
        //     path: `/login?redirect=${this.$route.query.redirect.toString()}&email=${this.email}`,
        //   });
        // } else {
        //   this.$router.push({
        //     path: `/login?email=${this.email}`,
        //   });
        // }
        // this.login();
      })
      .catch((e) => {
        if (e.response !== undefined) {
          if (e.response.status === 422) {
            this.error = "Please provide a valid email and password";
          } else {
            this.error = e.response.data.detail;
          }
        } else {
          this.error = "Server is not responding";
        }
      });
    }

    get progress() {
        return Math.min(100, this.password.length * 10);
    }

    get color() {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)];
    }

    public getReferralWithQueryParams() {
    if (typeof this.$route.query.redirect === "string") {
      const urlParams = new URLSearchParams();
      if (typeof this.$route.query.queryparams === "object") {
        for (const [k, v] of Object.entries(this.$route.query.queryparams)) {
          if (typeof v === "string") {
            urlParams.set(k, v);
          }
        }
        return this.$route.query.redirect + "?" + urlParams.toString();
      }
      return this.$route.query.redirect;
    }
    return "";
  }

  public login() {
    dispatchLogIn(this.$store, {
      username: this.email,
      password: this.password,
      to: this.getReferralWithQueryParams(),
    })
      .then((response) => {})
      .catch((e) => {
        console.log(e.response);
        if (e.response !== undefined) {
          if (e.response.status === 422) {
            this.error = "Please provide a valid username and password";
          } else {
            this.error = e.response.data.detail;
          }
        } else {
          this.error = "Server is not responding";
        }
      });
  }

}


import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { dispatchLogIn } from "@/store/main/actions";
import Tos from "@/components/Tos.vue";
@Component({
  components: {
    Tos,
  },
})
export default class TosView extends Vue {

}


import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readToken } from "@/store/main/getters";
import { dispatchLogIn } from "@/store/main/actions";

@Component
export default class Consent extends Vue {
  public email: string = "";
  public password: string = "";
  public appName = appName;
  public error: string = "";
  public loading: boolean = false;
  public clientLogoName: string = "";
  public success: boolean = false;

  public code: any = "";
  public state: any = "";
  public integration: any = "";

  get token() {
    return readToken(this.$store);
  }

  public mounted() {
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;
    this.integration = this.$route.query.integration;
  }
}
